/** @jsx jsx */
import * as React from "react"
import { Fragment } from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"
import ReactJWPlayer from "react-jw-player"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Button from "../../components/button"
import tw, { styled } from "twin.macro"
import { jsx, css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faReact } from "@fortawesome/free-brands-svg-icons"
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons"
import { Formik, useFormik } from "formik"
import { PieChart } from "react-minimal-pie-chart"
import PromoBlocks from "../../components/standardPromoBlock"
import IndexHero from "../../components/indexHero"
import IndexSwiper from "../../components/indexSwiper"

import * as Yup from "yup"
import ReactPlayer from "react-player"
import IndexTestimonialSwiper from "../../components/indexTestimonialSwiper"
import IndexSolutions from "../../components/indexSolutions"
import IndexProducts from "../../components/indexProducts"
import IndexCaseStudies from "../../components/indexCaseStudies"
import IndexBlogs from "../../components/indexBlogs"
import IndexLeadership from "../../components/indexLeadership"
import IndexWhitepaper from "../../components/indexWhitepaper"
import NewEvalForm from "../../components/newEvalForm"
import IndexLogos from "../../components/indexLogos"
import IndexIndustryRecognition from "../../components/indexIndustryRecognition"
import IndexInvestors from "../../components/indexInvestors"
import IndexFreeTrial from "../../components/indexFreeTrial"

const customArchitectureStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .75)",
    zIndex: "999",
  },
  content: {
    background: "none",
    // backgroundColor: '#000',
    padding: "0",
    // marginTop: "20vh",
    marginLeft: "auto",
    marginRight: "auto",
    border: "0",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
  },
}

Modal.setAppElement("body")

const ArchitectureImage = () => {
  return (
    <div className={"flex items-center justify-center border border-white rounded-5xl bg-white h-[90vh] w-[90vw]"}>
      <StaticImage
        src={"../../images/saasReferenceArchitectureBig.png"}
        className={""}
        imgClassName={"w-full"}
        quality={95}
        objectFit={"contain"}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt={"SaaS reference architecture"}
      />
    </div>
  )
}

function ZycadaArchitectureModal() {
  let subtitle
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#FFF"
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div className={"p-6 hover:border hover:border-zycadaDarkPurple border border-white rounded-5xl"}>
      <a onClick={openModal}>
        <StaticImage
          src="../../images/saasReferenceArchitecture.png"
          className={""}
          imgClassName={""}
          quality={95}
          objectFit={"contain"}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="SaaS reference architecture"
          styles={{}}
          loading={"eager"}
          placeholder={"none"}
        />
      </a>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={customArchitectureStyles}
        contentLabel="Zycada video modal"
        overlayClassName={""}
        
      >
        <h2 ref={_subtitle => (subtitle = _subtitle)}></h2>
        <ArchitectureImage></ArchitectureImage>
      </Modal>
    </div>
  )
}

const SolutionsSaas = () => (
  <Layout>
    <SEO title="Zycada powers the fastest, most responsive SaaS applications with AI." />

    <div className="">
      <div className={"pt-24"}>
        <div className="row pt-24 ">
          <div className="">
            <div className="container flex flex-col justify-center">
              <div className="row w-full">
                <div className=" text-center sm:text-left">
                  <h1 className=" display-4 fs-5 text-zycadaDarkPurple ">
                    Zycada for SaaS
                  </h1>

                  <div className={" sm:text-left"}>
                    <p className={"text-base"}>
                      COVID-19 has propelled our world into a new era:
                      Perimeter-less Enterprise. With that come new challenges.
                      Employees are now working from home with limited resources
                      and not on enterprise grade networking infrastructure. To
                      make this problem worse, employees are also forced to use
                      solutions that run on the public cloud and not on-prem.
                      How can enterprises adapt to this change?
                    </p>

                    <p className={"text-base"}>
                      Enterprises need a solution that will immediately boost
                      employee productivity when they are working from home.
                      They can do that by boosting the network speed (bandwidth)
                      of the employee's ISP and by boosting app responsiveness
                      to help employees stay productive. Unfortunately
                      enterprises neither control the ISPs nor the cloud
                      applications. Therefore, the only way to boost employee
                      productivity is to accelerate cloud applications and
                      increase their network bandwidth.
                    </p>

                    <p className={"text-base"}>
                      <strong> Accelerate SaaS applications</strong> - Zycada's
                      programmble intelligent edge platform uses{" "}
                      <strong>Cloud Service Accelerator</strong> to understand
                      how employees interact with SaaS applications and use that
                      to boost the responsiveness of cloud applications. As
                      traffic for different cloud apps flows through{" "}
                      <strong>Zycada's Cloud Orchestrator</strong>, it acts as a
                      private and secure edge network and boosts the cloud app's
                      responsiveness 3x-10x.
                    </p>

                    <p className={"text-base"}>
                      <strong>Boost network bandwidth</strong> - The second most
                      important challenge is to ensure employees have access to
                      consistent network connections at home. Upgrading the home
                      router hardware and incurring installation challenges will
                      affect the enterprise bottom line. Zycada's
                      <strong>Wireless Mile Accelerator</strong> dynamically
                      adapts to employees' last mile connectivity conditions and
                      boost throughput without changing their existing hardware
                      or ISP.
                    </p>
                    <p className={"text-base"}>
                      Furthermore, enterprises will now have increased
                      observability even when employees use cloud apps without
                      compromising employee privacy.
                    </p>
                  </div>
                </div>
              </div>
              {/*<div className="row md:w-1/2">*/}
              {/*  <div className="text-center align-items-center">*/}
              {/*    <div className="text-center align-items-center">*/}
              {/*      <ZycadaModal></ZycadaModal>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div className="pt-6 text-center align-items-center"></div>*/}
              {/*</div>*/}
              <div className="pt-4 pb-4 text-center">
                <div className={"p-2 mx-auto"}>
                  <ZycadaArchitectureModal></ZycadaArchitectureModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <IndexFreeTrial />

    {/*<p>*/}
    {/*<Link to="/page-2/">Go to page 2</Link> <br />*/}
    {/*<Link to="/using-typescript/">Go to "Using TypeScript"</Link>*/}
    {/*</p>*/}
  </Layout>
)

export default SolutionsSaas
